/**
 * Copyright 2018-2022 Streamlit Inc.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *    http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

/* Mono, regular */

@font-face {
  font-family: "Source Code Pro";
  font-weight: 400;
  font-style: regular;
  /* IMPORTANT: Always use a relative path! */
  src: url("../fonts/Source_Code_Pro/SourceCodePro-Regular.ttf")
    format("truetype");
}

@font-face {
  font-family: "Source Code Pro";
  font-weight: 600;
  font-style: regular;
  /* IMPORTANT: Always use a relative path! */
  src: url("../fonts/Source_Code_Pro/SourceCodePro-SemiBold.ttf")
    format("truetype");
}

@font-face {
  font-family: "Source Code Pro";
  font-weight: 700;
  font-style: regular;
  /* IMPORTANT: Always use a relative path! */
  src: url("../fonts/Source_Code_Pro/SourceCodePro-Bold.ttf")
    format("truetype");
}

/* Mono, italic */

@font-face {
  font-family: "Source Code Pro";
  font-weight: 400;
  font-style: italic;
  /* IMPORTANT: Always use a relative path! */
  src: url("../fonts/Source_Code_Pro/SourceCodePro-Italic.ttf")
    format("truetype");
}

@font-face {
  font-family: "Source Code Pro";
  font-weight: 600;
  font-style: italic;
  /* IMPORTANT: Always use a relative path! */
  src: url("../fonts/Source_Code_Pro/SourceCodePro-SemiBoldItalic.ttf")
    format("truetype");
}

@font-face {
  font-family: "Source Code Pro";
  font-weight: 700;
  font-style: italic;
  /* IMPORTANT: Always use a relative path! */
  src: url("../fonts/Source_Code_Pro/SourceCodePro-BoldItalic.ttf")
    format("truetype");
}

/* Sans, regular */

@font-face {
  font-family: "Source Sans Pro";
  font-weight: 400;
  font-style: regular;
  /* IMPORTANT: Always use a relative path! */
  src: url("../fonts/Source_Sans_Pro/SourceSansPro-Regular.ttf")
    format("truetype");
}

@font-face {
  font-family: "Source Sans Pro";
  font-weight: 600;
  font-style: regular;
  /* IMPORTANT: Always use a relative path! */
  src: url("../fonts/Source_Sans_Pro/SourceSansPro-SemiBold.ttf")
    format("truetype");
}

@font-face {
  font-family: "Source Sans Pro";
  font-weight: 700;
  font-style: regular;
  /* IMPORTANT: Always use a relative path! */
  src: url("../fonts/Source_Sans_Pro/SourceSansPro-Bold.ttf")
    format("truetype");
}

/* Sans, italic */

@font-face {
  font-family: "Source Sans Pro";
  font-weight: 400;
  font-style: italic;
  /* IMPORTANT: Always use a relative path! */
  src: url("../fonts/Source_Sans_Pro/SourceSansPro-Italic.ttf")
    format("truetype");
}

@font-face {
  font-family: "Source Sans Pro";
  font-weight: 600;
  font-style: italic;
  /* IMPORTANT: Always use a relative path! */
  src: url("../fonts/Source_Sans_Pro/SourceSansPro-SemiBoldItalic.ttf")
    format("truetype");
}

@font-face {
  font-family: "Source Sans Pro";
  font-weight: 700;
  font-style: italic;
  /* IMPORTANT: Always use a relative path! */
  src: url("../fonts/Source_Sans_Pro/SourceSansPro-BoldItalic.ttf")
    format("truetype");
}

/* Serif, regular */

@font-face {
  font-family: "Source Serif Pro";
  font-weight: 400;
  font-style: regular;
  /* IMPORTANT: Always use a relative path! */
  src: url("../fonts/Source_Serif_Pro/SourceSerifPro-Regular.ttf")
    format("truetype");
}

@font-face {
  font-family: "Source Serif Pro";
  font-weight: 600;
  font-style: regular;
  /* IMPORTANT: Always use a relative path! */
  src: url("../fonts/Source_Serif_Pro/SourceSerifPro-SemiBold.ttf")
    format("truetype");
}

@font-face {
  font-family: "Source Serif Pro";
  font-weight: 700;
  font-style: regular;
  /* IMPORTANT: Always use a relative path! */
  src: url("../fonts/Source_Serif_Pro/SourceSerifPro-Bold.ttf")
    format("truetype");
}

/* Serif, italic */

@font-face {
  font-family: "Source Serif Pro";
  font-weight: 400;
  font-style: italic;
  /* IMPORTANT: Always use a relative path! */
  src: url("../fonts/Source_Serif_Pro/SourceSerifPro-Italic.ttf")
    format("truetype");
}

@font-face {
  font-family: "Source Serif Pro";
  font-weight: 600;
  font-style: italic;
  /* IMPORTANT: Always use a relative path! */
  src: url("../fonts/Source_Serif_Pro/SourceSerifPro-SemiBoldItalic.ttf")
    format("truetype");
}

@font-face {
  font-family: "Source Serif Pro";
  font-weight: 700;
  font-style: italic;
  /* IMPORTANT: Always use a relative path! */
  src: url("../fonts/Source_Serif_Pro/SourceSerifPro-BoldItalic.ttf")
    format("truetype");
}
